import React from "react"
import { graphql } from "gatsby"
import Layout from "src/components/Layout"
import SEO from "src/components/seo"
import Link from "gatsby-link"
import LazyLoadImage from "src/components/LazyLoadImage"

class SiteIndex extends React.Component {
  orderedSeasons() {
    const seasons = this.props.data.allSeasonsJson.edges

    return seasons.slice().sort((a, b) => {
      const aCanonicalSplit = a.node.canonical.split("-")
      const bCanonicalSplit = b.node.canonical.split("-")

      const aYear = Number(aCanonicalSplit[aCanonicalSplit.length - 1])
      const bYear = Number(bCanonicalSplit[bCanonicalSplit.length - 1])

      const aSeason = aCanonicalSplit[0]
      const bSeason = bCanonicalSplit[0]

      if (bYear < aYear) return -1
      if (bYear > aYear) return 1
      if (aSeason < bSeason) return -1
      if (aSeason > bSeason) return 1
      return 0
    })
  }

  render() {
    const seasons = this.orderedSeasons()

    return (
      <Layout>
        <SEO title="Home" />

        <div id="index">
          <div className="seasons">
            {seasons.map(season => (
              <Link
                key={season.node.canonical}
                to={`/${season.node.canonical}/`}
                className="season"
              >
                <LazyLoadImage
                  wrapperClassName="season__image"
                  alt={season.title}
                  src={`/images/${season.node.teaser.src}`}
                  height={400}
                  delayTime={1000}
                />
                <span>{season.node.title}</span>
              </Link>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default SiteIndex

export const query = graphql`
  query IndexQuery {
    allSeasonsJson {
      edges {
        node {
          title
          canonical
          teaser {
            src
          }
        }
      }
    }
  }
`
